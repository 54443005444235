

































































































































































import { PackageIcon, PackageIconLabels } from '@/models/packages/PackageIcon';
import {
  PackageIconColor,
  PackageIconColorLabels,
  PackageIconTextColor
} from '@/models/packages/PackageIconColor';
import { Component, Vue } from 'vue-property-decorator';
import PackageIconComponent from '@/components/PackageIconComponent.vue';

@Component<CreatePackagePage>({
  components: {
    PackageIconComponent
  }
})
export default class CreatePackagePage extends Vue {
  private invalidStrings = ['\0'];
  private error = '';
  private selectedColor = PackageIconColor.GRAY;
  private submitted = false;
  private iconColorCss = PackageIconTextColor;
  private iconColorLabels = PackageIconColorLabels;
  private iconLabels = PackageIconLabels;
  private iconColors = Object.values(PackageIconColor).filter(
    (x) => typeof x === 'number'
  );
  private icons = Object.values(PackageIcon).filter(
    (x) => typeof x === 'number'
  );
  private pack = {
    name: '',
    family: '',
    description: '',
    icon: PackageIcon.NONE,
    iconColor: PackageIconColor.GRAY
  };

  async createPackage(): Promise<undefined> {
    if (!this.validForm()) {
      return;
    }
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    await this.$store.dispatch('packageModule/createPackage', this.pack);
    this.$router.push({
      name: 'package.collection'
    });
  }

  private selectColor(selectedIndex: number) {
    this.selectedColor = selectedIndex;
    this.pack.iconColor = selectedIndex;
  }

  private selectIcon(selectedIndex: number) {
    this.pack.icon = selectedIndex;
  }

  private hasEmptyForm(): boolean {
    return (
      this.pack.name === '' ||
      this.pack.family === '' ||
      this.pack.description === ''
    );
  }

  private validForm(): boolean {
    if (this.hasEmptyForm()) {
      return false;
    }
    const invalidStrings = this.invalidStrings;
    function isInvalidString(value: string) {
      return invalidStrings.some((substring) => value.includes(substring));
    }
    if (isInvalidString(this.pack.name)) {
      this.error = 'Name contains invalid characters';
      return false;
    }
    if (isInvalidString(this.pack.family)) {
      this.error = 'Family contains invalid characters';
      return false;
    }
    if (isInvalidString(this.pack.description)) {
      this.error = 'Description contains invalid characters';
      return false;
    }
    return true;
  }
}
